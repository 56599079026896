import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  ActuatorCircuitStatus,
  ActuatorPortTypeText,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  LightSensorPortText,
  SortDirection,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  accent,
  ambient,
  countDecimals,
  genericTableSort,
  global,
  isDefined,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  getDetailFields,
  getSensorsOrActuatorsSummaryFields,
  getSummaryFields,
  getTitle,
  transformDhcpStatusValue,
} from '@energybox/react-ui-library/dist/utils/inspection';

import React, { useEffect, useState } from 'react';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DataField, DataFieldProps } from '../Pdf/utils';
import { StyleSheet, View } from '@react-pdf/renderer';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
};

const styles = StyleSheet.create({
  localOverrideColorOutputStatus: {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: ambient.basePlus75,
  },
  trueOutput: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: accent.main,
    border: `2px solid ${accent.main}`,
  },
  falseOutput: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    border: `2px solid ${ambient.basePlus75}`,
  },
  commonCircuit: {
    textAlign: 'center',
    padding: '1 13',
    margin: '2 0',
  },
  localOverrideColorCircuitStatus: {
    color: ambient.basePlus50,
    border: `0.5px solid ${ambient.main}`,
    backgroundColor: ambient.basePlus75,
  },
  circuitStatusOn: {
    color: 'white',
    border: `1px solid ${accent.main}`,
    backgroundColor: accent.main,
  },
  circuitStatusOff: {
    color: ambient.basePlus50,
    border: `1px solid ${ambient.basePlus75}`,
    backgroundColor: 'transparent',
  },
});

const renderOutputStatusByState = (
  state: boolean | undefined,
  isLocalOverrideActive?: boolean
) => {
  if (state === undefined) {
    return global.NOT_AVAILABLE;
  }
  return {
    ...(isLocalOverrideActive ? styles.localOverrideColorOutputStatus : {}),
    ...(state && isLocalOverrideActive !== true
      ? styles.trueOutput
      : styles.falseOutput),
  };
};

const circuitStatusVisual = (
  status: ActuatorCircuitStatus,
  isLocalOverrideActive?: boolean
) => ({
  ...styles.commonCircuit,
  ...(isLocalOverrideActive === true
    ? styles.localOverrideColorCircuitStatus
    : styles[`circuitStatus${status}`]),
});

export const SiteController: React.FC<Props> = ({ data }) => {
  const [isLocalOverrideActive, setIsLocalOverrideActive] = useState(false);
  useEffect(() => {
    const isActive: boolean =
      (data.override_switch as InspectionDataField)?.field === true;
    setIsLocalOverrideActive(isActive);
  }, [data.override_switch]);

  const lightSensorRange = data.light_sensor_range?.['field'];
  const lightSensorRangeValue =
    lightSensorRange !== null ? lightSensorRange : global.NOT_AVAILABLE;

  const fields = [
    {
      name: 'IP Address',
      key: 'IP',
    },
    {
      name: 'Local Override',
      key: 'override_switch',
      transformValue: value => (value ? 'ON' : 'OFF'),
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'Relay Release Timing',
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
    },
    {
      name: 'DHCP Status',
      key: 'ip_setting',
      transformValue: transformDhcpStatusValue,
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
    },
    {
      name: 'Default Gateway',
      key: 'default_gateway',
    },
    {
      isHr: true,
    },
    {
      name: 'Subnet Mask',
      key: 'subnet_mask',
    },
    {
      name: 'Light Sensor Config',
      key: 'light_sensor_port',
      transformValue: value =>
        LightSensorPortText[value] || value || global.NOT_AVAILABLE,
      description: `( ${lightSensorRangeValue} )`,
    },
    {
      name: 'DNS 1',
      key: 'DNS_1',
    },
    {
      name: 'Light Sensor Reading',
      key: 'light_sensor_reading',
      transformValue: value => {
        let formattedValue;
        if (isNaN(value) || !isDefined(value)) {
          formattedValue = value;
        } else {
          const valueFloat = parseFloat(value);
          if (countDecimals(valueFloat) > 2) {
            formattedValue = formatDecimalValue(valueFloat, 2, 'lux');
          } else {
            formattedValue = `${value} lux`;
          }
        }
        return formattedValue;
      },
    },
    {
      name: 'DNS 2',
      key: 'DNS_2',
    },
    {
      name: 'Interval',
      key: 'light_sensor_interval',
      transformValue: value => `${value} min`,
    },
  ];

  const subtitle = getTitle(data);
  const actuators = (data.relays as InspectionDataFieldsByKey[]) || [];
  const siteControllerSummaryFields = getSummaryFields(data);
  const actuatorsSummaryFields = getSensorsOrActuatorsSummaryFields(actuators);
  const actuatorsColumns = [
    {
      header: 'Port',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: true,
      cellContent: ({ port_number }) => <DataField field={port_number} />,
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'port_number',
          'field',
        ]);
      },
    },
    {
      header: 'Relay Name',
      width: '10%',
      defaultSortDirection: SortDirection.ASC,
      cellContent: ({ title }) => <DataField field={title} />,
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
          'field',
        ]);
      },
    },
    {
      header: 'Equipment',
      width: '10%',
      cellContent: ({ equipment_title }) => (
        <DataField field={equipment_title} />
      ),
    },
    {
      header: 'Type',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      cellContent: ({ port_type }) => (
        <DataField
          field={port_type}
          transform={(field: string) =>
            ActuatorPortTypeText[field] || global.NOT_AVAILABLE
          }
          style={{ color: accent.main }}
        />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'port_type',
          'field',
        ]);
      },
    },
    {
      header: 'Output Status',
      width: '10%',
      align: 'center',
      cellContent: ({ output_status }) => {
        const field = renderOutputStatusByState(
          output_status?.field,
          isLocalOverrideActive
        );
        const fieldProps: DataFieldProps = {
          field: output_status,
          ...(field === global.NOT_AVAILABLE
            ? {
                transform: () => field,
              }
            : {
                transform: () => ' ',
                style: field,
                containerStyle: { width: 'auto' },
              }),
        };
        return (
          <DataField
            {...fieldProps}
            Container={({ children }) => (
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-around' }}
              >
                {children}
              </View>
            )}
          />
        );
      },
    },
    {
      header: 'Circuit Status',
      width: '10%',
      align: 'center',
      cellStyle: {
        padding: '0.25rem',
      },
      cellContent: ({ circuit_status }) => {
        const status = circuit_status.field
          ? ActuatorCircuitStatus.ON
          : ActuatorCircuitStatus.OFF;
        const style = circuitStatusVisual(status, isLocalOverrideActive);
        return (
          <DataField
            field={circuit_status}
            transform={() =>
              circuit_status?.field === true || circuit_status?.field === false
                ? status
                : global.NOT_AVAILABLE
            }
            style={style}
            Container={({ children }) => (
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-around' }}
              >
                {children}
              </View>
            )}
          />
        );
      },
    },
    {
      header: 'Control Mode',
      width: '10%',
      cellContent: ({ control_mode }) => (
        <DataField
          field={control_mode}
          transform={renderControlMode}
          style={isLocalOverrideActive ? { color: ambient.basePlus75 } : {}}
        />
      ),
    },
  ] as Columns<any>[];

  return (
    <InspectionComponent
      title={InspectionComponentName.SITE_CONTROLLER}
      titleIcon={<DeviceIcon type={InspectionComponentName.SITE_CONTROLLER} />}
      subtitle={subtitle}
      summaryFields={siteControllerSummaryFields}
      detailFields={getDetailFields(fields, data, InspectionDetailLevel.ALL)}
      detailTableHeader={
        <ComponentHeader
          title={'Relays'}
          summaryFields={actuatorsSummaryFields}
        />
      }
      detailTable={{
        dataIsLoading: false,
        columns: actuatorsColumns,
        data: actuators,
      }}
      noTableDataMessage="No Relays Configured"
    />
  );
};

const renderControlMode = (controlMode: string | undefined) => {
  if (!isDefined(controlMode)) return ' ';

  switch (controlMode) {
    /* Change the control_mode.field text here to be consistent with other parts of the platforms */
    case 'Normal':
      return WorkingModeLabel.NORMAL;

    case undefined:
    case null:
      return global.NOT_AVAILABLE;

    default:
      return controlMode;
  }
};
