import {
  CurrentUser,
  DateFormat,
  defaultLocale,
  Locale,
  MapDateFormat,
  MapFullTimeFormat,
  MapTimeFormat,
  MeasurementSystem,
  TimeFormat,
  TimeZone,
} from '@energybox/react-ui-library/dist/types';
import { Actions as AppActions } from '../actions/app';
import { Actions as UserActions } from '../actions/users';

export interface App {
  isInitialised: boolean;
  accessToken?: string;
  currentOrganizationId?: number;
  currentUser?: CurrentUser;
  locale: Locale;
  timezones?: TimeZone[];
  userIsInitialized: boolean;
}

const initialState: App = {
  isInitialised: false,
  userIsInitialized: false,
  locale: defaultLocale,
};

const sanitizedApiTimezone = (data: any) => ({
  title: data.title,
  commonName: data.title.replace(/_/g, ' '),
  offset: data.offset,
  offsetMinutes: data.offsetMinutes,
});

const currentUserFromApiResponse = (data: any): CurrentUser => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  phone: data.phone,
  role: data.role,
  organizationId: data.organizationId,
  organizationTitle: data.organization && data.organization.title,
  temperature: data.temperature,
  area: data.area,
  measurementSystem: data.measurementSystem,
  dashboardProfiles: [],
  userStatus: data.userStatus,
});

const tokenMeta = (token: string) => {
  if (!token) return;

  const tokenData = JSON.parse(atob(token.split('.')[1]));

  return {
    currentOrganizationId: tokenData.org,
  };
};

const determineLocale = (
  measurementSystem: MeasurementSystem | undefined,
  dateFormat: DateFormat | undefined,
  timeFormat: TimeFormat | undefined
): Locale => {
  const {
    measurementSystem: defaultMeasurementSystem,
    dateFormat: defaultDateFormat,
    timeFormat: defaultTimeFormat,
    dateTimeFormat: defaultDateTimeFormat,
    fullTimeFormat: defaultFullTimeFormat,
    fullDateTimeFormat: defaultFullDateTimeFormat,
  } = defaultLocale;

  return {
    measurementSystem: measurementSystem || defaultMeasurementSystem,
    timeFormat: timeFormat ? MapTimeFormat[timeFormat] : defaultTimeFormat,
    dateFormat: dateFormat ? MapDateFormat[dateFormat] : defaultDateFormat,
    dateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapTimeFormat[timeFormat]}`
        : defaultDateTimeFormat,
    fullTimeFormat: timeFormat
      ? MapFullTimeFormat[timeFormat]
      : defaultFullTimeFormat,
    fullDateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapFullTimeFormat[timeFormat]}`
        : defaultFullDateTimeFormat,
  };
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case AppActions.INIT:
    case AppActions.INIT_WITH_TOKEN:
      return {
        ...state,
        isInitialised: true,
      };

    case AppActions.ACCESS_TOKEN_UPDATED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        ...tokenMeta(action.payload.accessToken),
      };

    case AppActions.CURRENT_USER_SUCCESS:
      const { measurementSystem, timeFormat, dateFormat } = action.payload;
      return {
        ...state,
        currentUser: currentUserFromApiResponse(action.payload),
        locale: determineLocale(measurementSystem, dateFormat, timeFormat),
      };

    case UserActions.USER_TYPE_SUCCESS: {
      return {
        ...state,
        userIsInitialized: true,
      };
    }
    case AppActions.ACCESS_TOKEN_CLEARED:
      return {
        ...initialState,
        isInitialised: true,
      };

    case AppActions.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.payload.map(sanitizedApiTimezone),
      };

    default:
      return state;
  }
};

export const isSignedIn = (state: App) => {
  return !!state.accessToken;
};
